import tony_img_1 from '../../images/Blind-angels-tony-profile.png';
import tony_img_1_mobile from '../../images/Blind-angels-tony-profile_mobile.png';

const fullName = "Tony Christon-Walker";

const copyListTony = [
    {
        type: 'text',
        content: "Tony Christon-Walker is an HIV advocate who has been working in Alabama for over two decades. He is the former director of prevention for AIDS Alabama and the co-founder of Birmingham Black Pride. Tony's story is featured in the first episode of <i>Blind Angels</i>, a new docuseries on HIV in the American South, produced by Courageous Studios in partnership with Gilead Sciences. In an exclusive interview with Gilead HIV, Tony talked about the work he has done in his community, including opening of a pre-exposure prophylaxis (PrEP) medical clinic, to help Black men overcome the social and structural barriers that can make it more difficult for them to access HIV prevention and care."
    },
    {
        type: "image",
        content: {
            src: tony_img_1,
            mobileSrc: tony_img_1_mobile,
            alt: "Tony sitting down and looking into the camera",
            caption: "Tony Christon-Walker"
        }
    },
    {
        type: 'text',
        content: "In 2017, the South had a greater proportion of new HIV diagnoses than all other regions of the United States combined. More than half of those diagnoses (53%) occurred in Black people, and more than half of those (6 out of 10) were among Black MSM.<sup>1</sup> There are many social and structural barriers, including poverty, racism, homophobia, and a lack of access to care that not only put Black MSM in the South at increased risk for HIV but may also make them less likely to get the HIV preventive care and treatment they need.<sup>2,3</sup>"
    },
    {
        type: "text",
        content: "In the interview, Tony talked about helping the men he works with access the HIV care they need. He said the work often starts with combatting stigma, which continues to be pervasive in the South, where it overlaps with discriminatory views of sexual orientation, substance abuse, poverty, and sex work.<sup>1,4</sup> Experiences of stigma may even occur in healthcare settings.<sup>5</sup> MSM may experience negative attitudes from a healthcare provider, breaches of confidentiality, HIV testing without consent, or even denial of preventive care and treatment.<sup>6</sup>"
    },
    {
        type: "text",
        content: "As Tony explains, the stigma and shame can become internalized, which can, in turn, keep people from taking care of themselves:"
    },
    {
        type: "blockquote",
        content: "“In order to get people linked to and retained in care, we first have to get them to realize that they're...worthy of the care that they’re going to receive.”",
        author: fullName
    },
    {
        type: "text",
        content: "In addition to social barriers like stigma and racism, people in the South often face structural barriers that can impact their ability to access care.<sup>7,8</sup> Some people in communities like Tony's don’t have flexible work schedules or paid time off and may feel like they have to choose between providing for their family and taking care of their health."
    },
    {
        type: "text",
        content: "Transportation can also be an issue.<sup>7-9</sup> Ideally, people live within a 30-minute drive of comprehensive, coordinated HIV care, but the drive time in Southern counties with the highest HIV prevalence may often be closer to 50 minutes—even exceeding an hour in some, especially rural, areas.<sup>9</sup> Some areas also may not offer adequate public transportation. For example, some cities in Alabama with more than 30,000 people have no public transportation options, and van routes may be booked up weeks in advance.<sup>10</sup> Without a reliable way to get to a clinic or healthcare provider’s office, some people living with HIV may miss appointments or stop seeking care entirely."
    },
    {
        type: "text",
        content: "Advocates like Tony often—quite literally—go the extra mile for the people they work with by driving them to appointments:"
    },
    {
        type: "blockquote",
        content: "“If someone needs to go to an appointment, we're going to make sure they make it to the appointment. I personally take people in my car to appointments because I realize how important it is.”",
        author: fullName
    },
    {
        type: "text",
        content: "Tony also noted that some healthcare providers have strict policies and cancel appointments if a patient is just a few minutes late. He called on healthcare providers to be more accommodating: “If someone's 15 minutes late, you don't know what it took for them just to be 15 minutes late. So why not just honor the fact that they're here?” "
    },
    {
        type: "text",
        content: "When he opened his own PrEP medical clinic, he tried to anticipate and plan around the barriers that he’d seen in his work. The clinic was open nights and on weekends so patients with less flexible work schedules could make appointments after work, and staff never turned anyone away for being late."
    },
    {
        type: "text",
        content: "Tony said his goal was to create a system that “didn’t focus on privilege.” Other healthcare providers in the South can take a similar approach by recognizing the barriers that prevent Black MSM from being linked to and retained in care and by taking proactive measures to help make access easier."
    }
]


export default copyListTony;