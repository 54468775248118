import * as React from "react";
import ArticleLayout from "../articles/article-layout";
import copyList from "./copyList";
import References from "../global/references.js";

export const Content = (props) => {
  return (
    <div className={`${props.show ? 'd-block' : 'd-none'}`}>
      <ArticleLayout
        page={props.page}
        ariaLabel="A man with glasses, Tony, sitting in front of street lights"
        videoStillAlt="A man sitting in a doctor’s office"
        copyList={copyList}
        subheading="Overcoming Barriers to HIV Care for Black MEN WHO HAVE SEX WITH MEN (MSM) in the AMERICAN South"
        heading="A Conversation with Alabama Advocate Tony Christon-Walker"
      />
      <References page={props.page} />
    </div>
  )
}

