import * as React from "react";
import { Component } from 'react';
import Layout from '../../../components/global/layout'
import { Content } from '../../../components/tony/content'


class Tony extends Component {
  render () {
  return (
    <>
      <Layout section="articles" page="tony">
        <Content section="articles" page="tony" />
      </Layout>
    </>
  )
  }
}

export default Tony;